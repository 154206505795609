import * as CredentialHandlerPolyfill from 'credential-handler-polyfill';

CredentialHandlerPolyfill.loadOnce();

export const claimCertificate = async (url: string, request={}): Promise<any> => {
  const storeCredentialQuery = new CredentialHandlerPolyfill.WebCredential(
    'VerifiablePresentation',
    request, {
      recommendedHandlerOrigins: ['https://gobekli.io/mobile'],
      protocols: {
        // TODO - Implement OID4VCI
        // OID4VCI: 'openid-credential-offer://?credential_offer=%7B%22credential_issuer%22%3A%22https%3A%2F%2Fqa.veresexchanger.dev%2Fexchangers%2Fz19jkmCrf4YiJUHGcdpCitqow%2Fexchanges%2Fz19rPjK488tnkfmraLEWBno6s%22%2C%22credentials%22%3A%5B%7B%22format%22%3A%22ldp_vc%22%2C%22credential_definition%22%3A%7B%22%40context%22%3A%5B%22https%3A%2F%2Fwww.w3.org%2F2018%2Fcredentials%2Fv1%22%2C%22https%3A%2F%2Fw3id.org%2Fcitizenship%2Fv1%22%5D%2C%22type%22%3A%5B%22VerifiableCredential%22%2C%22PermanentResidentCard%22%5D%7D%7D%5D%2C%22grants%22%3A%7B%22urn%3Aietf%3Aparams%3Aoauth%3Agrant-type%3Apre-authorized_code%22%3A%7B%22pre-authorized_code%22%3A%225ad3d71f-b2c3-409a-9094-418a080c4e31%22%7D%7D%7D',
        vcapi: url
      }
    }
  );
  navigator.credentials.store(storeCredentialQuery);
};
