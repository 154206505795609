import React from 'react';
import { useAuth } from 'react-oidc-context';
import './App.css';
import Home from './components/Home';
import Login from './components/Login';

function App() {
  const auth = useAuth();

  const {
    isAuthenticated,
    isLoading,
    error,
    signinRedirect,
  } = auth;

  const getAppLayout = () => {
    if (isLoading) {
      return <div className="App">Loading...</div>;
    }

    if (error) {
      return <div className="App">Oops... {error.message}</div>;
    }

    if (!isAuthenticated) {
      return (
        <div className="App">
          <Login doLogin={signinRedirect} />
        </div>
      );
    }

    return (
      <div className="App">
        <Home auth={auth} />
      </div>
    );
  };

  return (
    <div>
      {getAppLayout()}
    </div>
  );
}

export default App;
