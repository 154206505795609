import React from 'react';
import Certificate from './Certificate';

type CertificatesProps = {
  urls: string[];
};

function Certificates(props: CertificatesProps) {
  const { urls } = props;

  const getCertificatesLayout = () => {
    if (!urls.length) {
      return <p>No certificates available to claim</p>;
    }
    return urls.map((url) => {
      const exchangeId = url.split('/').pop() as string;
      return (
        <Certificate key={exchangeId} url={url} />
      );
    });
  };

  return (
    <div>
      <h1>Certificates</h1>
      {getCertificatesLayout()}
    </div>
  );
}

export default Certificates;
