import React from 'react';
import { claimCertificate } from '../util/credentials';
import '../App.css';

type CertificateProps = {
  url: string;
};

function Certificate(props: CertificateProps) {
  const { url } = props;

  const doClaimCertificate = async () => {
    claimCertificate(url);
  };

  const badgeImage = "https://img.icons8.com/external-others-zufarizal-robiyanto/64/000000/external-achievement-mutuline-e-learning-others-zufarizal-robiyanto-2.png"
  const badgeNameCutoff = 8;
  const exchangeId = url.split('/').pop() as string;
  const badgeName = exchangeId.substring(0, badgeNameCutoff);

  return (
    <div>
      <div onClick={doClaimCertificate}>
        <span className="App-button"><img src={badgeImage} alt={`Certificate`} width="20" height="20" />{" "}<strong>{`Claim certificate ${badgeName}`}</strong></span>
      </div>
      <br />
    </div>
  );
}

export default Certificate;
