import React, { useCallback, useEffect, useState } from 'react';
import { Circles } from 'react-loader-spinner';
import { AuthContextProps } from 'react-oidc-context';
import Divider from '@material-ui/core/Divider';
import { env } from '../util/common';
import Certificates from './Certificates';

const VC_API_URL = env.VC_API_URL;

type HomeProps = {
  auth: AuthContextProps;
};

function Home(props: HomeProps) {
  const { auth } = props;
  const username = auth.user?.profile.name ||
                   auth.user?.profile.nickname ||
                   auth.user?.profile.email ||
                   auth.user?.profile.sub;

  const [loadingExchanges, setLoadingExchanges] = useState(true);
  const [exchangeUrls, setExchangeUrls] = useState([]);
  const getCredentialExchangeUrls = useCallback(async () => {
    try {
      const exchangesEndpoint = `${VC_API_URL}/exchanges`;
      const getExchangesConfig = {
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`
        }
      };
      const exchangeIds = await (await fetch(exchangesEndpoint, getExchangesConfig)).json();
      const exchangeUrls = exchangeIds.map((exId: string) => `${VC_API_URL}/exchanges/${exId}`);
      setExchangeUrls(exchangeUrls);
    } catch (error) {
      console.error('encountered exchange initiation error:', error);
    } finally {
      setLoadingExchanges(false);
    }
  }, [auth, setLoadingExchanges, setExchangeUrls]);

  useEffect(() => {
    getCredentialExchangeUrls();
  }, [getCredentialExchangeUrls]);

  return (
    <div>
      <div className="App-header">
        <div style={{float: "left"}}><p><strong>Hello {username}{" "}</strong></p></div>
        <div style={{float: "right"}} className="App-button" onClick={auth.removeUser}>Logout</div>
      </div>
      <Divider />
      {loadingExchanges &&
        <Circles
          color="blue"
          height={50}
          width={50}
        />
      }
      {!loadingExchanges &&
        <Certificates urls={exchangeUrls} />
      }
    </div>
  );
}

export default Home;
