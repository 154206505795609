import React from 'react';
import logo from '../assets/logo-white.png';

type LoginProps = {
  doLogin: (args?: any) => Promise<void>;
};

function Login(props: LoginProps) {
  const { doLogin } = props;
  return (
    <div className="Login">
      <img src={logo} className="App-logo" alt="logo" />
      <h1>Credential Portal</h1>
      <div className="App-button" onClick={doLogin}>Login</div>
    </div>
  );
}

export default Login;
